import React from 'react'
import { Button, InputField, PhoneNumberField } from './form-inputs';
import { DownloadIcon } from 'views/pages/dashboard/assets/icons';

const AddEditCustomer = ({ formik, previewUrl, handleImageChange, selectedCustomers, staff }: any) => {
	return (
		<>
			<div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							{!staff && <h6 className="mb-0"> {selectedCustomers ? 'Edit Customer' : 'Create New Customer'}</h6>}
							{staff && <h6 className="mb-0"> {selectedCustomers ? 'Edit Staff' : 'Create New Staff'}</h6>}
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<form onSubmit={formik.handleSubmit}>
							<div className="profile-form modal-body">
								<div className="row">
									<div className="col-4">
										<div className="avatar-upload">
											<input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" onChange={handleImageChange} />
											<label htmlFor="imageUpload"></label>
											<div className="avatar-preview">
												<div className="image-preview">
													<a href="#">
														{previewUrl && (
															<img
																src={previewUrl}
																alt="Avatar Preview"
																width={515}
																height={220}
															/>
														)}
														{formik?.values?.images?.path &&
															<img
																src={formik?.values?.images?.path}
																alt="Avatar Preview"
																width={515}
																height={220}
															/>
														}
													</a>
													<p>
														<img
															src={DownloadIcon}
															alt="Avatar Preview"
															width={30}
															height={30}

														/> &nbsp;&nbsp;Upload Image Here ...</p>
												</div>
												<div className="edit-images">
												</div>
											</div>
										</div>
									</div>
									<div className="col-8">
										<InputField
											label="Name"
											name="name"
											required={true}
											placeholder="Enter here"
											value={formik.values.name}
											onChange={formik.handleChange}
											error={formik.touched.name && formik.errors.name}
										/>

										<div className="col-12">
											<InputField
												type="email"
												name="email"
												label="Email"
												required={true}
												value={formik.values.email}
												onChange={formik.handleChange}
												error={formik.touched.email && formik.errors.email}
											/>
										</div>

										<div className="col-12">
											<PhoneNumberField
												className="custom-phone-number-field"
												label="Phone Number"
												name="phone_number"
												country="in"
												value={formik.values.phone_number}
												onChange={(phone_number) => {
													formik.setFieldValue('phone_number', phone_number);
												}}
												disabled={false}
												placeholder="Enter phone number..."
												error={formik?.touched.phone_number && formik.errors.phone_number}
											/>
										</div>
									</div>
								</div>

								<div className="add-new-gemstone mt-2">
									<div className="row">
										<div className="col-6">
											<InputField
												label="Date of Birth"
												name="dob"
												type="date"
												placeholder="Enter here"
												value={formik.values.dob}
												onChange={formik.handleChange}
												error={formik?.touched.dob && formik.errors.dob}
											/>
										</div>

										<div className="col-6">
											<InputField
												label="Birth Place"
												name="birth_place"
												placeholder="Enter here"
												value={formik.values.birth_place}
												onChange={formik.handleChange}
												error={formik?.touched.birth_place && formik.errors.birth_place}
											/>
										</div>

										<div className="col-6">
											<InputField
												label="Birth Time"
												name="tob"
												type="time"
												placeholder="Enter here"
												value={formik.values.tob}
												onChange={formik.handleChange}
												error={formik?.touched.tob && formik.errors.tob}
											/>
										</div>

										<div className="col-6">
											<InputField
												label="Role"
												name="role"
												placeholder="Enter here"
												value={staff ? 'Staff' : 'Customer'}
												onChange={formik.handleChange}
												disabled
												error={formik?.touched.role && formik.errors.role}
											/>
										</div>

										<div className="col-6">
											<InputField
												label="Gender"
												name="gender"
												placeholder="Enter here"
												value={formik.values.gender}
												onChange={formik.handleChange}
												error={formik?.touched.gender && formik.errors.gender}
											/>
										</div>

										<div className="col-6">
											<div className="form-group">
												<label>Status</label>
												<select
													className="form-select"
													style={{ padding: '10px' }}
													value={formik.values.status}
													onChange={(e: any) => formik.setFieldValue('status', e.target.value)}
												>
													<option value="active" disabled={formik?.values?.status === 'active'}>Active</option>
													<option value="pending" disabled={formik?.values?.status === 'pending'}>Pending</option>
													<option value="suspended" disabled={formik?.values?.status === 'suspended'}>Suspended</option>
													<option value="deleted" disabled={formik?.values?.status === 'deleted'}>Deleted</option>
												</select>
											</div>
										</div>
									</div>
								</div>

							</div>
							<div className="modal-footer text-end">
								<Button
									type="submit"
									className="btn btn-primary"
									label={selectedCustomers ? 'Edit User' : 'Add User'}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	)
}

export default AddEditCustomer
