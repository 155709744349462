import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { AppDispatch } from 'redux/store'

import { authService } from 'services'
import { setLoggedInUser, setTokenSession } from 'redux/slices/session.slice'

import * as Yup from 'yup'
import { useFormik } from 'formik'

import './login.scss'
import { Logo } from 'assets/images'
import { HandMobile } from './images'

import { routes } from 'utils'
import { Button, InputField } from 'views/components'
import { toast } from 'react-toastify'

const Login = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch<AppDispatch>()

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<{ message?: string, error?: boolean }>({})

	const formik = useFormik({
		initialValues: { email: '', password: '' },
		enableReinitialize: true,
		validationSchema: Yup.object({
			email: Yup.string()
				.label('Email')
				.required('Email is required')
				.email('Email Must Be A Valid Email'),
			password: Yup.string().label('Password').required()
		}),

		onSubmit: async (values) => {
			setError({});
			setLoading(true);
			try {
				const data: any = await authService.login(values);
				if (data.error === false) {
					toast.success(data.message, { style: { backgroundColor: '#b7e9bc' } })
					dispatch(setTokenSession(data.access_token));
					dispatch(setLoggedInUser());
					setTimeout(() => {
						navigate(`${routes.dashboard}`);
					}, 3000)
				} else {
					setError(data);
				}
			} catch (err: any) {
				setError({ error: true, message: err.message || 'An unexpected error occurred' });
			} finally {
				setLoading(false);
			}
		}
	})

	return (
		<div className="auth-form align-middles">
			<div className="col-lg-8 col-md-12">
				<div className="card">
					<div className="row">
						<div className="col-lg-6 col-md-12 my-auto">
							<div className="login-form-col">
								<div className="brand-logo">
									<img src={Logo} alt="Logo" width={15} height={15} className="brand-logo-images" />
									<h4>Login</h4>
									<p>Enter your credentials to access your account.</p>
								</div>

								<form onSubmit={formik.handleSubmit}>
									<InputField
										type="email"
										name="email"
										label="Email"
										// autoFocus
										required={true}
										value={formik.values.email}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik?.touched.email && formik.errors.email}
									/>
									<div className="login-password-field">
										<InputField
											type="password"
											name="password"
											label="Password"
											required={true}
											value={formik.values.password}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={formik?.touched.password && formik.errors.password}
										/>
										{error?.message && <p className="text-danger text-capitalize mb-3">{error?.message}</p>}
									</div>

									<Button name="" type="submit" className={`btn btn-primary ${loading ? 'btn-loading' : ''} `} label="Login" disabled={loading} />
									<div className="forgot-password-link d-flex justify-content-between align-items-center my-3">
										<p>We&apos;re here to help you&nbsp;<a className="btn btn-link" href={'mailto:hello@jahanviastro.com'}>Contact Us</a></p>
										<Link className="btn btn-link" to="/forgot-password">Forgot Password?</Link>
									</div>
								</form>

							</div>
						</div>
						<div className="col-lg-6 col-md-12">
							<div className="hand-moblie-col">
								<img src={HandMobile} alt="Hand Mobile" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login