import { toast } from 'react-toastify';
import store from 'store';

export default class CommonService {
	baseURL: string;

	constructor() {
		this.baseURL = process.env.REACT_APP_BASE_URL || '';
	}

	async request(endpoint: string, method: string, params?: any) {
		const headers = {
			'Content-Type': 'application/json',
			'Authorization': `${store.get(process.env.REACT_APP_ACCESS_TOKEN_KEY || 'bIESIcg42Wb9')}`
		};

		const config: RequestInit = { method, headers };

		if (method !== 'GET' && params) {
			config.body = params instanceof FormData ? params : JSON.stringify(params);
		}

		// Check if the query params include a company
		const url = new URL(`${this.baseURL}/v1/${endpoint}`);
		const isCompanyEndpoint = url.searchParams.has('company');

		const fullURL = isCompanyEndpoint ? url.toString() : `${this.baseURL}/v1/admin/${endpoint}`;

		try {
			const response = await fetch(fullURL, config);
			const data = await response.json();
			if (data.error === false) {
				// toast.success(data?.message, {
				// 	autoClose: 3000
				// })
			} else if (data.error === true) {
				// this.showErrorToast(data?.message);

				if (data.message === 'Unauthorized access!') {
					store.clearAll()
					window.location.replace('/login');
				}
			}

			return data;
		} catch (error: any) {
			// this.showErrorToast(error?.message);

			if (error.message === 'Unauthorized access!') {
				store.clearAll()
				window.location.replace('/login');
			}
			return error;
		}
	}

	showErrorToast(message: string) {
		const location = window.location.pathname;
		if (location !== '/login') {
			toast.error(message, {
				autoClose: 3000
			});
		}
	}

	async get(endpoint: string) {
		return await this.request(endpoint, 'GET');
	}

	async post(endpoint: string, params = {}) {
		return await this.request(endpoint, 'POST', params);
	}

	async put(endpoint: string, params = {}) {
		return await this.request(endpoint, 'PUT', params);
	}

	async delete(endpoint: string) {
		return await this.request(endpoint, 'DELETE');
	}
}
