import React, { useEffect, useState } from 'react';
import './style.scss'
import { _Object } from 'utils/interfaces';
import { bookingsService } from 'services/booking.service';
import { enquirieService } from 'services';
import { capitalizeFirstLetter, dateFormat } from 'utils';
import { customersService } from 'services/customers.service';

const Dashboard = () => {
	const [enquires, setEnquires] = useState<_Object>({});
	const [bookings, setBookings] = useState<_Object>({});
	const [customers, setCustomers] = useState<_Object>({});

	const [loading, setLoading] = useState<boolean>(false);

	const getBooking = () => {
		setLoading(true)
		bookingsService.getBookings().then((data) => {
			setBookings(data)
			setLoading(false)
		})
	}

	const getEnquires = () => {
		setLoading(true)
		enquirieService.getEnquires().then((data) => {
			setEnquires(data)
			setLoading(false)
		})
	}

	const getCustomers = () => {
		setLoading(true)
		const filter = { 'filters[role]': 'customer' }
		customersService.getCustomers(filter).then((data) => {
			setCustomers(data)
			setLoading(false)
		})
	}

	useEffect(() => {
		getBooking()
		getEnquires()
		getCustomers()
	}, [])
	const totalSum = bookings?.items?.reduce((sum: any, item: any) => {
		return sum + item.total_price;
	}, 0);

	const bookingStatusList = ['upcoming', 'completed', 'cancelled'];
	const enquiryStatusList = ['new', 'open', 'close'];
	const customerStatusList = ['active', 'pending', 'suspended'];

	const statusCount: any = {
		upcoming: 0,
		completed: 0,
		cancelled: 0
	};

	const enqStatusCount: any = {
		open: 0,
		close: 0,
		new: 0
	};

	const customerStatusCount: any = {
		active: 0,
		pending: 0,
		suspended: 0
	};

	bookings?.items?.forEach((item: any) => {
		if (bookingStatusList?.includes(item?.status)) {
			statusCount[item.status]++;
		}
	});

	enquires?.items?.forEach((item: any) => {
		if (enquiryStatusList?.includes(item?.status)) {
			enqStatusCount[item.status]++;
		}
	});

	customers?.items?.forEach((item: any) => {
		if (customerStatusList?.includes(item?.status)) {
			customerStatusCount[item.status]++;
		}
	});

	return (
		<section className="dashboard-section">
			<div className="row mb-4">
				<div className="col-lg-3 col-md-6 col-12 col-12">
					<div className="chart-card">
						<div className="card">
							<h4>{bookings?.pagination?.total_items}</h4>
							<p className="appointment-sub-heading">Appointments</p>
							<ul className="list-inline">
								{Object.entries(statusCount)?.map(([status, count]: any) => {
									return (
										<>
											<li className="list-inline-item">{capitalizeFirstLetter(status)} <span>{count}</span> </li>
										</>
									)
								})}
							</ul>

						</div>
					</div>
				</div>

				<div className="col-lg-3 col-md-6 col-12 col-12">
					<div className="chart-card">
						<div className="card">
							<h4> {enquires?.pagination?.total_items}</h4>
							<p className="appointment-sub-heading">Inquiries</p>
							<ul className="list-inline">
								{Object.entries(enqStatusCount)?.map(([status, count]: any) => {
									return (
										<>
											<li className="list-inline-item">{capitalizeFirstLetter(status)}<span>{count}</span></li>
										</>
									)
								})}
							</ul>
						</div>
					</div>
				</div>

				<div className="col-lg-3 col-md-6 col-12 col-12">
					<div className="chart-card">
						<div className="card">
							<h4> {totalSum}</h4>
							<p className="appointment-sub-heading">Revenue</p>
						</div>
					</div>
				</div>

				<div className="col-lg-3 col-md-6 col-12 col-12">
					<div className="chart-card">
						<div className="card">
							<h4>{customers?.pagination?.total_items}</h4>
							<p className="appointment-sub-heading">Customers</p>
							<ul className="list-inline">
								{Object.entries(customerStatusCount)?.map(([status, count]: any) => {
									return (
										<>
											<li className="list-inline-item">{capitalizeFirstLetter(status)}<span>{count}</span></li>
										</>
									)
								})}
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className="dashboard-table-content">
				<div className="row">
					<div className="col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="appointment-table">
							<div className="card">
								<div className="card-header space-between align-middles">
									<h5>Bookings</h5>
									<a href="/app/bookings">View All</a>
								</div>
								<div className="card-body">
									<div className="">
										<table className="table align-middle">
											<thead>
												<tr>
													<th>#</th>
													<th>Full Name</th>
													<th>Contact Info.</th>
													<th>Price</th>
													<th>Status</th>
												</tr>
											</thead>

											<tbody>
												{loading ?
													<tr>
														<td colSpan={12} className="text-center">
															<div className="d-flex justify-content-center">
																<div className="spinner-border text-secondary" role="status">
																</div>
															</div>
														</td>
													</tr>
													:
													bookings?.items?.length === 0 ?
														<tr>
															<td colSpan={6} className="text-center">
																No record found!
															</td>
														</tr>
														:
														bookings?.items?.map((item: any, i: number) => {
															return (
																<>
																	{i <= 4 &&
																		<tr key={i}>
																			<td>{i + 1}</td>
																			<td>
																				<p className="mb-1 text-capitalize">{item.user.name}</p>
																			</td>
																			<td>
																				<a href={`mailto:${item.customer_email}`}>
																					<p className="mb-1">{item.email}</p>
																				</a>
																				<a href={`tel:${item.phone_number}`}>
																					<p className="mb-1">{item.phone_number}</p>
																				</a>
																			</td>
																			{item?.total_price && <td>
																				<p className="mb-1">
																					₹{item?.total_price}
																				</p>
																			</td>}
																			<td>
																				<p className="mb-1 text-capitalize">
																					{capitalizeFirstLetter(item?.status)}
																				</p>
																			</td>
																		</tr>}
																</>
															)
														})
												}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="appointment-table">
							<div className="card">
								<div className="card-header space-between align-middles" >
									<h5 className="mb-0">Inquiries</h5>
									<a
										href="/app/inquiries"
									>
										View All
									</a>
								</div>
								<div className="card-body p-0">
									<div className="">
										<table className="table align-middle">
											<thead>
												<tr>
													<th>#</th>
													<th>Name</th>
													<th>Contact info.</th>
													{/* <th>Created date</th> */}
													<th style={{ whiteSpace: 'nowrap' }}>Last conversion</th>
												</tr>
											</thead>

											<tbody>
												{loading ?
													<tr>
														<td colSpan={12} className="text-center">
															<div className="d-flex justify-content-center">
																<div className="spinner-border text-secondary" role="status">
																</div>
															</div>
														</td>
													</tr>
													:
													enquires?.items?.length === 0 ?
														<tr>
															<td colSpan={6} className="text-center">
																No record found!
															</td>
														</tr>
														:
														enquires?.items?.map((item: { id: string, name: string, email: string, phone_number: number, created_at: string, updated_at: string }, i: number) => {
															return (
																<>
																	{i <= 4 &&
																		<tr key={item.name + i}>
																			<td>{i + 1}</td>
																			<td>
																				<p className="mb-1 text-capitalize">{item.name}</p>
																			</td>
																			<td>
																				<a href={`mailto:${item.email}`}>
																					<p className="mb-1">{item.email}</p>
																				</a>
																				<a href={`tel:${item.phone_number}`}>
																					<p className="mb-1">{item.phone_number}</p>
																				</a>
																			</td>

																			{/* <td>
																				<p className="mb-1 text-capitalize">
																					{dateFormat(item.created_at)}
																				</p>
																			</td> */}
																			<td>
																				<p className="mb-1 text-capitalize">
																					{dateFormat(item.updated_at)}
																				</p>
																			</td>
																		</tr>}
																</>
															)
														})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Dashboard