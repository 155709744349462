import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from 'redux/store'

import { setLoggedInUser } from 'redux/slices/session.slice'
import { getAcronym } from 'utils'
import './style.scss'
import { _Object } from 'utils/interfaces'
// import { NotificationIcon } from './assets/icons'

const Header = () => {
	const dispatch = useDispatch<AppDispatch>()

	const { loggedInUser } = useSelector((state: _Object) => state.session)

	useEffect(() => {
		!loggedInUser?.id && dispatch(setLoggedInUser())
	}, [])

	// const notify = () => {
	// 	toast.error('No noification found!');
	// }

	return (
		<header className="main-header">
			<div className="space-between align-middles">
				<h6>Dashboard</h6>
				<ul className="list-inline mb-0">
					{/* <li className="list-inline-item">
						<a href="#" onClick={notify}><img src={NotificationIcon} alt="Notification" height={16} width={16} /></a>
					</li>
					<li className="list-inline-item me-2">
						|
					</li> */}
					<a href="/app/profile"><li className="list-inline-item">
						<div className="dropdown user-profile">

							{loggedInUser.name} {loggedInUser.name && <span className="avtar-user ms-2">{loggedInUser?.images?.path ? <img src={loggedInUser?.images?.path} width={30} height={30} /> : getAcronym(loggedInUser.name)}</span>}
						</div>
					</li>
					</a>
				</ul>
			</div>
		</header>
	)
}

export default Header
