import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { gemsService } from 'services/gems.service';
import { closeModal } from 'utils';
import { _Object } from 'utils/interfaces';
import { Button, InputField, WysiwygEditor } from 'views/components';
import axios from 'axios';
import store from 'store';
import { DownloadIcon } from '../assets/icons';

const initialValue = {
	title: '',
	description: '',
	benefits: '',
	how_to_use: '',
	status: 'published'
};

const AddEditGems = ({ selectedGems, getGems }: _Object) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [details, setDetails] = useState<any>();

	const [selectedFile, setSelectedFile] = useState<any>(null);
	const [previewUrl, setPreviewUrl] = useState('');

	const modalElement = document.getElementById('AddEditGems');
	const modalElementClose = document.getElementById('addEditModalClose');

	const formik: any = useFormik({
		initialValues: initialValue,
		enableReinitialize: true,
		validationSchema: Yup.object({
			title: Yup.string().label('Title').required(),
			description: Yup.string().label('Description').required(),
			benefits: Yup.string().label('Benefit').required(),
			how_to_use: Yup.string().label('How to use').required()
		}),
		onSubmit: (values) => {
			setLoading(true);
			if (selectedGems) {
				gemsService.updateGems(selectedGems, {
					description: values.description,
					title: values.title,
					benefits: values.benefits,
					how_to_use: values.how_to_use,
					status: values.status
				}).then((data) => {
					if (!data.error) {
						if (selectedFile) {
							handleUpload(selectedGems)
							getGems()
						} else {
							getGems()
						}
						formik.resetForm()
						closeModal('AddEditGems')
						toast.success(data.message, { style: { backgroundColor: '#b7e9bc' } })
					} else {
						toast.error(data?.message);
					}
					setLoading(false)
				})
			} else {
				gemsService.createGems(values).then((data: _Object) => {
					if (!data.error) {
						handleUpload(data?.data?.id)
						toast.success(data.message, { style: { backgroundColor: '#b7e9bc' } })
						formik.resetForm()
						getGems()
						closeModal('AddEditGems')
					} else {
						toast.error(data?.message);
					}
					setLoading(false)
				})
			}
		}
	});

	useEffect(() => {
		if (selectedGems) {
			gemsService.getGemsDetails(selectedGems).then((data) => {
				setDetails(data);
				formik.setValues(data);
			});
		} else {
			// Reset Formik values to initial blank state
			setPreviewUrl('')
			setSelectedFile(null)
			formik.resetForm({
				values: { title: '', description: '', benefits: '', how_to_use: '' }
			});
			formik.setFieldValue('status', 'published')
		}
	}, [selectedGems, modalElement, modalElementClose]);

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files ? e.target.files[0] : null;
		if (file) {
			setSelectedFile(file);
			setPreviewUrl(URL.createObjectURL(file));
		}
	};

	const handleUpload = async (id: string) => {
		if (selectedFile) {
			const data = new FormData();
			data.append('entity_id', id);
			data.append('files', selectedFile);
			try {
				const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/admin/media`, data, {
					headers: { 'Content-Type': 'multipart/form-data', 'Authorization': store.get(process.env.REACT_APP_ACCESS_TOKEN_KEY || 'bIESIcg42Wb9') }
				});
				response && getGems()
			} catch (error) {
				console.error('Error uploading image:', error);
			}
		}
	}

	console.log('rrrrrrrrrrrrrrrr', formik?.values);

	return (
		<>
			<div
				className="modal fade"
				id="AddEditGems"
				aria-labelledby="AddEditGems"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content">
						<form onSubmit={formik.handleSubmit}>
							<div className="modal-header">
								<h5 className="mb-0">{selectedGems ? 'Edit' : 'Add New'} Gemstone</h5>
								<button
									type="button"
									id="addEditModalClose"
									className="btn-close"
									onClick={() => {
										formik.resetForm()
										setSelectedFile(null)
									}}
									data-bs-dismiss="modal"
									aria-label="Close"
								></button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-4">
										<div className="avatar-upload">
											<input
												type="file"
												id="imageUpload"
												accept=".png, .jpg, .jpeg"
												onChange={handleImageChange}
											/>
											<label htmlFor="imageUpload"></label>
											<div className="avatar-preview">
												<div className="image-preview">
													<a href="#">
														{previewUrl && (
															<img
																src={previewUrl}
																alt=""
																width={240}
																height={220}
															/>
														)}
														{selectedGems && details?.images?.path &&
															<img
																src={details?.images?.path}
																alt=""
																width={240}
																height={220}
															/>
														}
													</a>
													<p>
														<img
															src={DownloadIcon}
															alt="Avatar Preview"
															width={30}
															height={30}

														/> &nbsp;&nbsp;Upload Image Here ...</p>
												</div>
												<div className="edit-images">

												</div>
											</div>
										</div>
									</div>
									<div className="col-8">
										<div className="row">
											<div className="col-6">
												<InputField
													type="text"
													name="title"
													label="Title"
													required={true}
													placeholder="Enter here"
													value={formik.values.title}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													error={
														(formik.touched.title && formik.errors.title) || ''
													}
												/>
											</div>

											<div className="col-6">
												<div className="form-group">
													<label>Status</label>
													<select
														className="form-select mb-1 text-capitalize" style={{ padding: '10px' }}
														value={formik?.values?.status}
														onChange={(e) => formik.setFieldValue('status', e.target.value)}
													>
														<option value="draft">Draft</option>
														<option value="published">Published</option>
													</select>
												</div>
											</div>
										</div>
										<div className="col-12">
											<WysiwygEditor
												name="description"
												value={formik.values.description}
												onChange={(e: any) => formik.setFieldValue('description', e)}
												label="Description"
												required={true}
												error={(formik.touched.description && formik.errors.description) || ''}
											/>

										</div>

									</div>
								</div>
								<div className="add-new-gemstone mt-2">
									<div className="row">
										<div className="col-12 mt-4">
											<WysiwygEditor
												name="benefits"
												value={formik.values.benefits}
												onChange={(e: any) => formik.setFieldValue('benefits', e)}
												label="Benefits"
												required={true}
												error={(formik.touched.benefits && formik.errors.benefits) || ''}
											/>

										</div>
										<div className="col-12 mt-4">
											<WysiwygEditor
												name="how_to_use"
												value={formik.values.how_to_use}
												onChange={(e: any) => formik.setFieldValue('how_to_use', e)}
												label="How to use"
												required={true}
												error={(formik.touched.how_to_use && formik.errors.how_to_use) || ''}
											/>

										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<Button type="submit" label={selectedGems ? 'Update' : 'Add Gem'} className={`btn btn-primary ${loading ? 'btn-loading' : ''} `}
									disabled={loading} />
							</div>
						</form>
					</div>
				</div>
			</div>

		</>
	);
};

export default AddEditGems;

