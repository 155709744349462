import React, { useEffect, useState } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import './style.scss'
import { ChatIcon, EditButton, Plus } from '../assets/icons';

import { chatService } from 'services';
import { bookingsService } from 'services/booking.service';

import { _Object } from 'utils/interfaces';
import { capitalizeFirstLetter, closeModal, dateFormat, timeFormat12 } from 'utils';

import { DeleteItem, InputField, Loading, TextArea } from 'views/components';
import Pagination from 'views/components/form-inputs/pagination';
import { PhoneNumberField } from 'views/components/form-inputs';
import OpenBookingDetails from './edit';
import { io } from 'socket.io-client';
import moment from 'moment';
import { customersService } from 'services/customers.service';
import { toast } from 'react-toastify';

const Bookings = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [bookings, setBookings] = useState<_Object>({});
	const [message, setMessage] = useState<string>();
	const [activeChatPerson, setActiveChatPerson] = useState<any>('');
	const [activeChat, setActiveChat] = useState<any>([]);
	const [innerLoading, setInnerLoading] = useState<boolean>(false);
	const [selectedChat, setSelectedChat] = useState<any>('');
	const [fees, setFees] = useState<any>();
	const [allStafs, setAllStafs] = useState<any>();

	const [filterData, setFilterData] = useState<_Object>({
		page: 1
	});
	const [selectedBookings, setSelectedBookings] = useState<string>('');

	const getFees = () => {
		setLoading(true)
		bookingsService.getFees().then((data) => {
			setFees(data.items)
			setLoading(false)
		})
	}

	const getBooking = () => {
		setLoading(true)
		bookingsService.getBookings(filterData).then((data) => {
			setBookings(data)
			setLoading(false)
		})
	}

	const getStaffs = () => {
		customersService.getCustomers({ page: '1', role: 'staff', 'filters[role]': 'staff' }).then((data: any) => {
			if (!data?.error) {
				setAllStafs(data.items)
			}
		})
	}

	useEffect(() => {
		getBooking()
		getFees()
		getStaffs()
	}, [filterData])

	const formik = useFormik({
		initialValues: filterData,
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			fullName: Yup.string().required('Full name is required'),
			email: Yup.string().email('Invalid email format').required('Email is required'),
			phone_number: Yup.string().required('Phone number is required')
		}),
		onSubmit: (values: _Object) => {
			const fees_id = fees?.find((fee: any) => fee.type === values.consultant)

			const finalValue = {
				'payment_status': values.payment_status,
				'full_name': values.fullName,
				'email': values.email,
				'phone_number': values.phone_number,
				'type': values.consultant,
				'status': values.status,
				'order_note': values.order_note,
				'assign_id': values.assign_id ? values.assign_id : null,
				'belongs_to': 'other',
				fees_id: fees_id.id
			}
			if (selectedBookings) {
				bookingsService.updateBookings(selectedBookings, finalValue).then((data: any) => {
					if (!data.error) {
						closeModal('new-booking-modal')
						toast.success(data.message, { style: { backgroundColor: '#b7e9bc' } })
						setMessage('')
					} else {
						setMessage(data.message)
					}
				})
			} else {
				bookingsService.createBookings(finalValue).then((data: any) => {
					if (!data.error) {
						closeModal('new-booking-modal')
						toast.success(data.message, { style: { backgroundColor: '#b7e9bc' } })
						setMessage('')
					} else {
						setMessage(data.message)
					}
				})
			}
		}
	});

	const deleteItem = () => {
		setLoading(true)
		bookingsService.deleteBooking(selectedBookings).then((data: _Object) => {
			!data.error && getBooking()
			setLoading(false)
			closeModal('DeleteBooking')
		})
	}

	const getBookingDetails = (id: string) => {
		setSelectedBookings(id);
		bookingsService.getBookingDetails(id).then((data: any) => {
			if (!data.error) {
				formik.setFieldValue('fullName', data.full_name);
				formik.setFieldValue('email', data.email);
				formik.setFieldValue('phone_number', data.phone_number);
				formik.setFieldValue('consultant', data.type);
				formik.setFieldValue('status', data.status);
				formik.setFieldValue('order_note', data.order_note);
				formik.setFieldValue('belongs_to', data.belongs_to);
				formik.setFieldValue('fees_id', data.fees_id);
				formik.setFieldValue('user_id', data.user_id);
				formik.setFieldValue('payment_status', data.payment_status);
				formik.setFieldValue('assign_id', data.assign_id ? data.assign_id : null);
			}
		});
	};

	const socket = io('https://api.jahanviastro.com', {
		secure: true,
		transports: ['websocket', 'polling']
	});

	const getChat = (id: any) => {
		chatService.getChatDetail(id).then((item: any) => {
			if (!item.error) {
				setActiveChat(item?.items)
				getSingleChat(id)
			}
		})
	}

	const getSingleChat = (id: string) => {
		setSelectedChat(id);
		if (id) {
			socket.emit('joinRoom', id);
			console.log(`Joined room: ${id}`);
		}

		const handleNewMessage = (data: any) => {
			setActiveChat((prevActiveChat: any) => {
				return [data, ...(prevActiveChat || [])];
			});
		};

		socket.on('newMessage', handleNewMessage);

		return () => {
			socket.off('newMessage', handleNewMessage);
			socket.disconnect();
		};
	};

	const formik1 = useFormik({
		initialValues: { message: '' },
		enableReinitialize: true,
		onSubmit: (values: _Object, { resetForm }) => {
			setInnerLoading(true)
			chatService.postChat(selectedChat, { message: values.message }).then((data: any) => {
				if (!data.error) {
					resetForm({})
					setInnerLoading(false)
				}
			})
		}
	});

	const activeFees = {
		type0: fees?.find((fee: any) => fee.type === '0')?.fees || 0,
		type1: fees?.find((fee: any) => fee.type === '1')?.fees || 0
	};

	const resetForm = () => {
		formik.resetForm({})
		setSelectedBookings('')
		formik.setFieldValue('payment_status', 'unpaid');
		formik.setFieldValue('assign_id', '');
		formik.setFieldValue('status', 'upcoming');
		formik.setFieldValue('consultant', '1');
		formik.setFieldValue('consultant', '1');
	}

	return (
		<>
			<section className="booking-section">
				<div className="container-fluid">
					<div className="row">
						<div className="card">
							<div className="card-header space-between align-middles">
								<h6 className="mb-0">Bookings</h6>
								<ul className="list-inline">
									<button
										type="button"
										className="btn btn-primary"
										data-bs-toggle="modal"
										data-bs-target="#new-booking-modal"
										onClick={() => resetForm()}
									>
										<img src={Plus} alt="Add" width={15} height={15} />&nbsp;
										Add New
									</button>
								</ul>
							</div>

							<div className="card-body table-responsive">
								<table className="table">
									<thead>
										<tr>
											<th>#</th>
											<th style={{ whiteSpace: 'nowrap' }}>Booking Id</th>
											<th style={{ whiteSpace: 'nowrap' }}>Full Name</th>
											<th style={{ whiteSpace: 'nowrap' }}>Booking Date</th>
											<th>Price</th>
											<th style={{ whiteSpace: 'nowrap' }}>Order Note</th>
											<th>Status</th>
											<th>Payment</th>
											<th>Consultant</th>
											<th style={{ whiteSpace: 'nowrap' }}>Booked For</th>
											<th className="action-button">Action</th>
										</tr>
									</thead>

									<tbody>
										{loading ?
											<tr>
												<td colSpan={12} className="text-center">
													<div className="d-flex justify-content-center">
														<div className="spinner-border text-secondary" role="status">
														</div>
													</div>
												</td>
											</tr>
											:
											bookings?.items?.length === 0 ?
												<tr>
													<td colSpan={12} className="text-center">
														No record found!
													</td>
												</tr>
												:
												bookings?.items?.map((item: any, i: number) => {
													return (
														<tr key={i}>
															<td>{i + 1}</td>
															<td>{item.order_number}</td>
															<td>
																<p className="mb-1 text-capitalize">{item?.user?.name}</p>
															</td>
															<td>
																<p className="mb-1 text-capitalize">{dateFormat(item?.created_at)}
																</p>
															</td>
															{item?.total_price && <td>
																<p className="mb-1">
																	₹{item?.total_price}
																</p>
															</td>}
															<td>
																<p className="mb-1 text-capitalize">
																	{item?.order_note ? item?.order_note : '-'}
																</p>
															</td>
															<td>
																<p className="mb-1 text-capitalize">{capitalizeFirstLetter(item?.status)}</p>
															</td>

															<td>
																<p className="mb-1 text-capitalize">{capitalizeFirstLetter(item?.payment_status)}</p>
															</td>
															<td>
																<p className="mb-1 text-capitalize">{capitalizeFirstLetter(item?.assignee ? item?.assignee?.name : '-')}</p>
															</td>

															<td>
																<p className="mb-1 text-capitalize">{capitalizeFirstLetter(item?.belongs_to ? item?.belongs_to : '-')}</p>
															</td>

															<td>
																<ul className="list-inline">

																	<li className="list-inline-item chat-icon">
																		<button type="button" data-bs-toggle="modal"
																			data-bs-target="#chatModalOpen"
																			onClick={() => { getChat(item?.chat?.id), setActiveChatPerson(item?.user?.name) }}
																			className="btn btn-primary p-0 border-0 eye-delete">
																			<img src={ChatIcon} alt="Chat" width={20} height={19} />
																		</button>
																	</li>

																	<li className="list-inline-item">
																		<a href="#" className="btn btn-success eye-edit p-0" type="button"
																			data-bs-toggle="modal" data-bs-target="#new-booking-modal"
																			onClick={() => getBookingDetails(item?.id)}
																		>
																			<img src={EditButton} alt="Edit" width={22} height={20} />
																		</a>
																	</li>
																</ul>
															</td>
														</tr>
													)
												})
										}
									</tbody>
								</table>
							</div>
							{bookings?.pagination?.total_pages > 1 &&
								<div className="card-footer">
									<div className="pagination-wrap">
										<Pagination
											total_pages={bookings?.pagination?.total_pages}
											current_page={bookings?.pagination?.current_page}
											onClick={(i: { [key: string]: number }) =>
												setFilterData((prev) => ({
													...prev,
													page: i.selected + 1
												}))
											}
										/>
									</div>
								</div>}
						</div>

					</div>
				</div>
			</section>

			<div className="modal fade" id="new-booking-modal" aria-labelledby="new-booking-modalLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content">
						<form onSubmit={formik.handleSubmit}>
							<div className="modal-header">
								<h5 className="mb-0" id="new-booking-modalLabel">{selectedBookings ? 'Edit Booking' : 'Create New Booking'}</h5>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="d-flex gap-5 mb-2">
										<p>Consultant</p>
										<div className="form-group">
											<div className="form-check">
												<input
													className="form-check-input"
													name="consultant"
													type="radio"
													id="gurma"
													value="1"
													onChange={formik.handleChange}
													checked={formik?.values.consultant === '1'}
												/>
												<label className="form-check-label" htmlFor="gurma">
													Guruma
												</label>
												<br />
												<small className="mt-1">{activeFees.type1}</small>
											</div>
										</div>
										<div className="form-group">
											<div className="form-check">
												<input
													className="form-check-input"
													name="consultant"
													type="radio"
													id="staff"
													value="0"
													onChange={formik.handleChange}
													checked={formik?.values.consultant === '0'}
												/>
												<label className="form-check-label" htmlFor="staff">
													Staff
												</label>
												<br />
												<small className="mt-1">{activeFees.type0}</small>
											</div>
										</div>
									</div>

									<div className="col-6">
										<InputField
											name="fullName"
											label="Full Name"
											required={true}
											placeholder="Enter here"
											onChange={formik.handleChange}
											value={formik.values.fullName}
											error={formik?.touched.fullName && formik.errors.fullName}
											onBlur={formik.handleBlur}
										/>
									</div>

									<div className="col-6">
										<div className="form-group">
											<label htmlFor="Status">Status</label>
											<select
												name="status"
												id="status"
												className="form-select" style={{ padding: '10px' }}
												value={formik.values.status}
												onChange={formik.handleChange}
											>
												<option value="upcoming">Upcoming</option>
												<option value="completed">Completed</option>
												<option value="cancelled">Cancelled</option>
											</select>
										</div>
									</div>

									<div className="col-6">
										<InputField
											name="email"
											label="Email"
											required={true}
											placeholder="Enter here"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.email}
											error={formik?.touched.email && formik.errors.email}
										/>
									</div>
									<div className="col-6">
										<PhoneNumberField
											className="custom-phone-number-field"
											label="Phone Number"
											country="in"
											name="phone_number"
											value={formik.values.phone_number}
											onBlur={formik.handleBlur}
											onChange={(phone_number) => { formik.setFieldValue('phone_number', phone_number) }}
											disabled={false}
											placeholder="Enter phone number..."
											error={formik?.touched.phone_number && formik.errors.phone_number}
										/>
									</div>

									<div className="col-6">
										<div className="form-group">
											<label htmlFor="assign_id">Payment Status</label>
											<select
												name="payment_status"
												id="payment_status"
												className="form-select"
												value={formik.values.payment_status}
												onChange={formik.handleChange}
											>
												<option value="paid">Paid</option>
												<option value="unpaid">Unpaid</option>
												<option value="refunded">Refunded</option>
											</select>
										</div>
									</div>
									<div className="col-6">
										<div className="form-group">
											<label htmlFor="assign_id">Assignee</label>
											<select
												name="assign_id"
												id="status"
												className="form-control"
												value={formik.values.assign_id}
												onChange={formik.handleChange}
											>
												{allStafs?.map((staff: any) => {
													return (
														<>
															<option value="">None</option>
															<option value={staff?.id}>{staff.name}</option>
														</>
													)
												})}
											</select>
										</div>
									</div>

									<TextArea
										name="order_note"
										label="Note"
										placeholder="Enter here"
										onChange={formik.handleChange}
										value={formik.values.order_note}
									/>

								</div>
							</div>
							<div className="modal-footer d-flex justify-content-between">
								<p className="text-danger">{message}</p>
								<button type="submit" className="btn btn-primary">Submit</button>
							</div>
						</form>
					</div>
				</div >
			</div >
			<OpenBookingDetails selectedBooking={selectedBookings} />
			<DeleteItem title={'Booking'} modalId={'DeleteBooking'} deleteItem={deleteItem} />

			<div className="modal fade" id="chatModalOpen" aria-labelledby="ChatModalLabel" aria-hidden="true" data-bs-backdrop="static" tabIndex={-1}>
				<div className="modal-dialog modal-sm chat-modal">
					<div className="modal-content" style={{ minHeight: '350px' }}>
						<div className="modal-header bg-white">
							<p className="mb-0 mx-auto">
								Chat with {(activeChatPerson)}
							</p>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body p-0 bg-light" style={{ maxHeight: '300px', overflowX: 'hidden' }}>
							<div className="chat-window p-3">
								{activeChat?.slice()?.reverse()?.reduce((acc: any[], chat: any) => {
									const currDate = dateFormat(chat?.updated_at);
									const todayDate = moment()?.format('MMM DD, yyyy');
									const lastGroup = acc[acc.length - 1];
									const isNewDay = !lastGroup || dateFormat(lastGroup.date) !== currDate;

									if (isNewDay) {
										acc?.push({
											date: currDate,
											isToday: currDate === todayDate,
											messages: []
										});
									}
									acc[acc.length - 1]?.messages?.push(chat);

									return acc;
								}, [])?.map((group: any, i: number) => (
									<React.Fragment key={i}>
										<small className="text-center">
											{group.isToday ? 'Today' : group.date}
										</small>

										{group?.messages?.map((chat: any, index: number) => (
											<React.Fragment key={index}>
												{chat?.user?.role === 'admin' && (
													<div className="chat-message me-3 text-end mb-2">
														<div className="d-inline-block p-2 bg-primary text-white rounded text-start">
															{chat?.message}&nbsp;&nbsp;
															<small>{timeFormat12(chat?.updated_at)}</small>
														</div>
													</div>
												)}

												{chat?.user?.role === 'customer' && (
													<div className="chat-message ms-3 text-start mb-2">
														<div className="d-inline-block p-2 bg-secondary text-white rounded text-start">
															{chat?.message}&nbsp;&nbsp;
															<small>{timeFormat12(chat?.updated_at)}</small>
														</div>
													</div>
												)}
											</React.Fragment>
										))}
									</React.Fragment>
								))}
							</div>

						</div>
						<div className="modal-footer bg-white p-2 chat-box-row">
							<form onSubmit={formik1.handleSubmit}>
								<div className="input-group">
									<input type="text" className="form-control chat-input-box" placeholder="Type your message..." name="message" value={formik1.values.message} aria-label="Chat input" onChange={formik1.handleChange} />
									<button className="btn btn-primary p-0 px-3" type="submit">{innerLoading ? <Loading /> : 'Send'}</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Bookings