import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { quotesService } from 'services/qutoes.services';
import { closeModal } from 'utils';
import { Button, InputField, TextArea } from 'views/components';
import { _Object } from 'utils/interfaces';

const initialValue = {
	name: '',
	description: ''
};

const AddEditQuotes = ({ selectedQuotes, getQutoes }: _Object) => {
	const [loading, setLoading] = useState<boolean>(false);

	const formik = useFormik({
		initialValues: initialValue,
		enableReinitialize: true,
		validationSchema: Yup.object({
			name: Yup.string().label('Name').required(),
			description: Yup.string().label('Description').required()
		}),
		onSubmit: (values) => {
			setLoading(true);
			if (selectedQuotes) {
				quotesService.updateQutoes(selectedQuotes, {
					description: values.description,
					name: values.name
				}).then((data) => {
					if (!data.error) {
						getQutoes()
						// formik.resetForm()
						// setBookingSlots([])
						closeModal('NewAppontment')
						toast.success(data.message, { style: { backgroundColor: '#b7e9bc' } })
					} else {
						toast.error(data?.message);
					}
					setLoading(false)
				})
			} else {
				quotesService.createQutoes(values).then((data: _Object) => {
					if (!data.error) {
						closeModal('NewAppontment')
						formik.resetForm()
						getQutoes()
						toast.success(data.message, { style: { backgroundColor: '#b7e9bc' } })
					} else {
						toast.error(data?.message);
					}
					setLoading(false)
				})
			}
		}
	});

	return (
		<>
			<div className="modal fade" id="NewAppontment" aria-labelledby="NewAppontment" aria-hidden="true" data-bs-backdrop="static">
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content">
						<form onSubmit={formik.handleSubmit}>
							<div className="modal-header">
								<h5 className="mb-0"> {selectedQuotes ? 'Edit' : 'New'} Quotes</h5>
								<button type="button" className="btn-close"
									// onClick={() => { formik.resetForm(); }}
									data-bs-dismiss="modal" aria-label="Close"></button>
							</div>
							<div className="modal-body">

								<div className="row">
									<InputField
										type="text"
										name="name"
										label="Author Name"
										required={true}
										placeholder="Enter here"
										value={formik.values.name}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik?.touched.name && formik.errors.name || ''}
									/>
									<TextArea
										label="Quote"
										name="description"
										value={formik.values.description}
										rows={4}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										required={true}
										placeholder="Enter here"
										// required={true}
										error={formik?.touched.description && formik.errors.description || ''}
									/>
								</div>

							</div>
							<div className="modal-footer">
								<Button type="submit" label={selectedQuotes ? 'Update' : 'Submit'} className={`btn btn-primary ${loading ? 'btn-loading' : ''} `}
									disabled={loading} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddEditQuotes;

