import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import './style.scss';

import * as Yup from 'yup';
import moment from 'moment';
import { useFormik } from 'formik';
import { io } from 'socket.io-client';

import { ReplyIcon } from '../assets/icons';
import { HandMobile } from 'views/pages/auth/images';

import { _Object } from 'utils/interfaces';

import { enquirieService } from 'services';
import { gemsService } from 'services/gems.service';

import { InputField } from 'views/components';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const Details = () => {
	const socket = io('https://api.jahanviastro.com', {
		secure: true,
		transports: ['websocket', 'polling']
	});

	const [loading, setLoading] = useState<boolean>(false);
	const [gems, setGems] = useState<_Object>({});
	const [gemsStatus, setGemsStatus] = useState<string>('');
	const [gemsDetails, setGemsDetails] = useState<_Object>({});
	const [comments, setComments] = useState<any[]>([]);
	const { loggedInUser } = useSelector((state: _Object) => state.session);

	const params = useParams();

	useEffect(() => {
		setLoading(true);
		getData();
	}, [params.id]);

	const getData = () => {
		enquirieService.getEnquiryDetails(params.id).then((data) => {
			setGems(data);
			setGemsStatus(data.status);
			gemsService.getGemsDetails(data?.gem_id).then((item) => {
				setGemsDetails(item);
			});
			setComments(data.comments || []);
			setLoading(false);
		});
	};

	const formikComment = useFormik({
		initialValues: {
			comment: ''
		},
		validationSchema: Yup.object({
			comment: Yup.string().required('Comment is required')
		}),
		onSubmit: async (values, { resetForm }) => {
			console.log('fffffffffffffff', values, loading, gemsDetails);
			const commentData = await enquirieService.postComment(params.id, { comment: values.comment })
			commentData.error && toast.success(commentData.message, { style: { backgroundColor: '#b7e9bc' } })
			socket.emit('newComment', { enquiryId: params.id, commentData });
			resetForm();
		}
	});

	const statusUpdate = async (value: string) => {
		const updateStatus = await enquirieService.updateEnquiry(params?.id, { status: value });
		console.log('updateStatus', updateStatus);
	};

	useEffect(() => {
		if (params.id) {
			socket.emit('joinRoom', params.id);
			console.log(`Joined room: ${params.id}`);
		}

		const handleNewComment = (data: any) => {
			setComments((prevComments) => [...prevComments, data]);
		};

		socket.on('newComment', handleNewComment);

		return () => {
			socket.off('newComment', handleNewComment);
			socket.disconnect();
		};
	}, [params.id, loggedInUser?.id]);

	return (
		<>
			<section className="details-edit-section">
				<div className="container-fluid">
					<div className="row">
						<div className="col-9 mb-3">
							<div className="customer-name-list">
								<div className="card">
									<ul className="list-inline">
										<li className="list-inline-item">
											<h6>Customer Name</h6>
											<p>{gems?.name}</p>
										</li>

										<li className="list-inline-item">
											<h6>Enquiry Id</h6>
											<p>{gems?.enquiry_number}</p>
										</li>

										<li className="list-inline-item">
											<h6>Create date</h6>
											<p>{moment(gems?.created_at).utc().format('DD MMM, YYYY HH:mm')}</p>
										</li>
										<li className="list-inline-item">
											<h6>Status</h6>

											<select
												className="form-select"
												aria-label="Default select example"
												onChange={(e) => { setGemsStatus(e.target.value); statusUpdate(e.target.value); }}
												value={gemsStatus}
											>
												<option value="new">New</option>
												<option value="open">Open</option>
												<option value="close">Closed</option>
											</select>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-9">
							{gems?.description &&
								<div className="card mb-1 comments-card-sticky sticky-top">
									<div className="comments-box border-bottom">
										<h6 className="mt-3">{gems?.description}</h6>
									</div>
								</div>
							}
							<div className="card">
								<div className="comments-box-row border-bottom">
									<h5>Comments</h5>
									{comments
										?.sort((a: any, b: any) => moment(a?.created_at).diff(moment(b?.created_at))) // Sort by created_at from old to new
										.map((comment: any, index: number) => {
											const commentDate = moment(comment?.created_at);
											const now = moment();
											const isWithin24Hours = now.diff(commentDate, 'hours') < 24;

											// Determine alignment based on user role
											const isAdmin = comment?.user?.role === 'admin';

											return (
												<div className={`comments-box-col ${isAdmin ? 'text-end' : 'text-start'}`} key={index}>
													<div className={`align-middles ${isAdmin ? 'flex-row-reverse' : ''}`}> {/* Reverse layout for admin */}
														<div className="flex-shrink-0">
															<img
																src={comment?.user?.image ? comment?.user?.image : HandMobile}
																alt="User"
																width={50}
																height={50}
															/>
														</div>

														<div className={`flex-grow-1 ms-3 ${isAdmin ? 'text-right pe-3' : ''}`}>
															<h6 className="mb-1">{comment?.user?.name}</h6>
															<small className="mb-0">
																{isWithin24Hours
																	? commentDate.fromNow() // Shows "X hours ago" if within 24 hours
																	: commentDate.format('DD MMM, YYYY hh:mm A')} {/* Shows full date if older than 24 hours */}
															</small>
															<p className="mt-1">{comment?.comment}</p>
														</div>
													</div>
												</div>
											);
										})}

								</div>
								<div className="card-footer card-footer-comment">
									<form onSubmit={formikComment.handleSubmit}>
										<div className="col-12">
											<InputField
												name="comment"
												label=""
												rows={4}
												required={true}
												placeholder="Post your comment"
												value={formikComment.values.comment}
												onChange={formikComment.handleChange}
												error={formikComment?.touched.comment && formikComment.errors.comment}
											/>
											<div className="reply-button">
												<button type="submit" className="btn">
													<img src={ReplyIcon} alt="Reply Icon" height={24} width={24} />
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Details;
