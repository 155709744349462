import React, { useEffect, useState } from 'react';
import './style.scss'

import { EyeButton } from '../assets/icons';
import { _Object } from 'utils/interfaces';
import { enquirieService } from 'services';
import { capitalizeFirstLetter, dateFormat, routes } from 'utils';
import Pagination from 'views/components/form-inputs/pagination';
import { useFormik } from 'formik';

const Inquiries = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [enquires, setEnquires] = useState<_Object>({});
	const [filterData, setFilterData] = useState<_Object>({
		page: 1
	});
	const getEnquires = () => {
		setLoading(true)
		enquirieService.getEnquires(filterData).then((data) => {
			setEnquires(data)
			setLoading(false)
		})
	}

	useEffect(() => {
		getEnquires()
	}, [filterData])

	const formik = useFormik({
		initialValues: filterData,
		enableReinitialize: true,
		onSubmit: (values: _Object) => {
			if (values.q.trim() !== '') {
				setFilterData((prev) => ({
					...prev,
					q: values.q,
					page: 1
				}));
			}
		}
	});
	console.log(formik)

	// const handleStatusChange = (e: any, id: any) => {
	// 	const updatedStatus = e?.target?.value;
	// 	enquirieService.updateEnquiry(id, { status: updatedStatus }).then((data: any) => {
	// 		!data.error && getEnquires()
	// 	})
	// };

	return (
		<>
			<section className="inquiry-section">
				<div className="container-fluid">
					<div className="row">
						<div className="card">
							<div className="card-header space-between align-middles">
								<h6 className="mb-0">Enquiries</h6>
							</div>

							<div className="card-body">
								<div className="table-responsive">
									<table className="table">
										<thead>
											<tr>
												<th className="enquiry-id" style={{ width: '50px' }}>#</th>
												<th className="enquiry-id" style={{ width: '100px', whiteSpace: 'nowrap' }}>Enquiry ID.</th>
												<th>Full Name</th>
												<th>Contact Info.</th>
												<th>Description</th>
												<th style={{ width: '130px', whiteSpace: 'nowrap' }}>Created date</th>
												<th style={{ width: '130px' }}>Status</th>
												<th className="action-button" style={{ width: '110px' }}>Action</th>
											</tr>
										</thead>

										<tbody>
											{loading ?
												<tr>
													<td colSpan={12} className="text-center">
														<div className="d-flex justify-content-center">
															<div className="spinner-border text-secondary" role="status">
															</div>
														</div>
													</td>
												</tr> :
												enquires?.items?.length === 0 ?
													<tr>
														<td colSpan={12} className="text-center">
															No record found!
														</td>
													</tr>
													:
													enquires?.items?.map((item: { enquiry_number: string, status: string, description: string, id: string, name: string, email: string, phone_number: number, created_at: string, updated_at: string }, i: number) => {
														return (
															<tr key={item.name + i}>
																<td>
																	{i + 1}
																</td>
																<td>
																	<p className="mb-1">{item.enquiry_number}</p>
																</td>
																<td>
																	<p className="mb-1 text-capitalize">{item.name}</p>
																</td>
																<td>
																	<a href={`mailto:${item.email}`}>
																		<p className="mb-1">{item.email}</p>
																	</a>
																	<a href={`tel:${item.phone_number}`}>
																		<p className="mb-1">{item.phone_number}</p>
																	</a>
																</td>
																<td>
																	<p className="mb-1 text-capitalize">
																		{item?.description?.slice(0, 30) ? item?.description?.slice(0, 30) : '-'}
																	</p>
																</td>

																<td>
																	<p className="mb-1 text-capitalize">
																		{dateFormat(item.created_at)}
																	</p>
																</td>
																<td>
																	<p className="mb-1 text-capitalize">{capitalizeFirstLetter(item.status)}</p>
																	{/* <select
																		className="form-select mb-1 text-capitalize status-select-field"
																		value={item?.status}
																		onChange={(e) => handleStatusChange(e, item?.id)}
																	>
																		<option value="open" disabled={item?.status === 'open'}>Open</option>
																		<option value="close" disabled={item?.status === 'close'}>Close</option>
																		<option value="new" disabled={item?.status === 'new'}>New</option>
																	</select> */}
																</td>

																<td>
																	<ul className="list-inline">
																		{/* <li className="list-inline-item">
																			<a href="#" className="btn btn-success eye-edit p-0" type="button">
																				<img src={EditButton} alt="Edit" width={22} height={20} />
																			</a>
																		</li> */}
																		<li className="list-inline-item">
																			<a href={`${routes.inquiry}/${item.id}`} className="btn btn-secondary eye-button p-0" type="button">
																				<img src={EyeButton} alt="Eye" width={22} height={20} />
																			</a>
																		</li>
																		{/* <li className="list-inline-item">
																			<button type="button" onClick={() => setSelectedEnquires(item?.id)} data-bs-toggle="modal" data-bs-target="#DeleteEnquiry" className="btn btn-primary p-0 border-0 eye-delete">
																				<img src={DeleteButton} alt="Delete" width={18} height={20} />
																			</button>
																		</li> */}
																	</ul>
																</td>
															</tr>
														)
													})}
										</tbody>
									</table>
								</div>
								{enquires?.pagination?.total_pages > 1 &&
									<div className="card-footer">
										<div className="pagination-wrap">
											<Pagination
												total_pages={enquires?.pagination?.total_pages}
												current_page={enquires?.pagination?.current_page}
												onClick={(i: { [key: string]: number }) =>
													setFilterData((prev) => ({
														...prev,
														page: i.selected + 1
													}))
												}
											/>
										</div>
									</div>}
							</div>
						</div>

					</div>
				</div>
			</section >
			{/* <DeleteEnquiry getEnquires={getEnquires} selectedEnquies={selectedEnquies} /> */}
		</>
	)
}

export default Inquiries