import React, { useEffect, useState } from 'react';
import './style.scss'

import axios from 'axios';
import store from 'store';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import { _Object } from 'utils/interfaces';
import { closeModal, dateFormat } from 'utils';
import { customersService } from 'services/customers.service';
import Pagination from 'views/components/form-inputs/pagination';
import { AddEditCustomer, DeleteItem, OpenCustomerDetails } from 'views/components';
import { DeleteButton, EditButton, Plus } from '../assets/icons';
import moment from 'moment';

const Customers = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [customers, setCustomers] = useState<_Object>({});
	const [selectedCustomers, setSelectedCustomers] = useState<any>('');
	const [selectedFile, setSelectedFile] = useState<any>(null);
	const [previewUrl, setPreviewUrl] = useState('');
	const [filterData, setFilterData] = useState<_Object>({
		page: 1,
		'filters[role]': 'customer',
		role: 'customer',
		password: 'Naisarai@00',
		name: '',
		email: '',
		phone_number: '',
		dob: '',
		birth_place: '',
		tob: '',
		gender: '',
		status: ''
	});
	const getCustomer = () => {
		setLoading(true)
		customersService.getCustomers(filterData).then((data) => {
			setCustomers(data)
			setLoading(false)
		})
	}

	useEffect(() => {
		getCustomer()
	}, [filterData])

	const formik: any = useFormik({
		initialValues: filterData,
		enableReinitialize: true,
		validationSchema: Yup.object({
			name: Yup.string().required('Name is required'),
			email: Yup.string().email('Invalid email address').required('Email is required'),
			phone_number: Yup.string().required('Phone number is required'),
			dob: Yup.string().required('Date of Birth is required'),
			tob: Yup.string().required('Time of Birth is required'),
			birth_place: Yup.string().required('Birth Place is required'),
			gender: Yup.string().required('Gender is required'),
			status: Yup.string().required('Status is required')
		}),
		onSubmit: (values: _Object) => {
			const { password, page, ...filteredValues } = values;
			delete filteredValues['filters[role]'];
			if (selectedCustomers) {
				customersService.updateCustomers(selectedCustomers, filteredValues).then((customer) => {
					if (!customer.error) {
						toast.success(customer.message, { style: { backgroundColor: '#b7e9bc' } })
						closeModal('exampleModal')
						if (selectedFile) {
							handleUpload(selectedCustomers)
						}
						getCustomer()
					}
					console.log('c', password, page);
				})
			} else {
				customersService.createCustomers(filteredValues).then((customer) => {
					if (!customer.error) {
						toast.success(customer.message, { style: { backgroundColor: '#b7e9bc' } })
						closeModal('exampleModal')
						if (selectedFile) {
							handleUpload(customer.user_id)
						}
						getCustomer()
					}
				})
			}
		}
	});

	const deleteItem = () => {
		setLoading(true)
		customersService.deleteCustomers(selectedCustomers).then((data: _Object) => {
			!data.error && getCustomer()
			setLoading(false)
			closeModal('DeleteCustomer')
			toast.success(data.message, { style: { backgroundColor: '#b7e9bc' } })
		})
	}

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files ? e.target.files[0] : null;
		if (file) {
			setSelectedFile(file);
			setPreviewUrl(URL.createObjectURL(file));
		}
	};

	const handleUpload = async (id: string) => {
		if (selectedFile) {
			const data = new FormData();
			data.append('entity_id', id);
			data.append('files', selectedFile);
			try {
				const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/admin/media`, data, {
					headers: { 'Content-Type': 'multipart/form-data', 'Authorization': store.get(process.env.REACT_APP_ACCESS_TOKEN_KEY || 'bIESIcg42Wb9') }
				});
				response && getCustomer()
			} catch (error) {
				console.error('Error uploading image:', error);
			}
		}
	}

	const getCustomerDetail = (id: string) => {
		setLoading(true);
		customersService.getCustomerDetails(id).then((item: _Object) => {
			formik.setValues((prevValues: _Object) => ({
				...prevValues,
				...item
			}));
			setLoading(false);
		});
	};

	// const handleStatusChange = (e: any, id: any) => {
	// 	const updatedStatus = e?.target?.value;
	// 	customersService.updateCustomers(id, { status: updatedStatus }).then((data: any) => {
	// 		!data.error && getCustomer()
	// 	})
	// };
	return (
		<>
			<section className="customer-section">
				<div className="container-fluid">
					<div className="row">
						<div className="card">
							<div className="card-header space-between align-middles">
								<h6>Customers</h6>
								<button
									type="button"
									className="btn btn-primary"
									data-bs-toggle="modal"
									data-bs-target="#exampleModal"
									onClick={() => { formik.resetForm(), setSelectedCustomers('') }}
								>
									<img src={Plus} alt="Add" width={15} height={15} />&nbsp;
									Add New
								</button>
							</div>

							<div className="card-body">
								<div className="table-responsive">
									<table className="table">
										<thead>
											<tr>
												<th>#</th>
												<th>Full Name</th>
												<th style={{ whiteSpace: 'nowrap' }}>Contact Info.</th>
												<th style={{ whiteSpace: 'nowrap' }}>DOB/Birth Time</th>
												<th style={{ whiteSpace: 'nowrap' }}>Birth Place</th>
												<th>Gender</th>
												<th>Status</th>
												<th className="action-button">Action</th>
											</tr>
										</thead>

										<tbody>
											{loading ?
												<tr>
													<td colSpan={12} className="text-center">
														<div className="d-flex justify-content-center">
															<div className="spinner-border text-secondary" role="status">
															</div>
														</div>
													</td>
												</tr>
												:
												customers?.items?.length === 0 ?
													<tr>
														<td colSpan={12} className="text-center">
															No record found!
														</td>
													</tr>
													:
													customers?.items?.map((item: any, i: number) => {
														return (
															<tr key={i}>
																<td>{i + 1}</td>
																<td>
																	<p className="mb-1 text-capitalize">
																		{item?.name}
																	</p>
																</td>
																<td>
																	<a href={`mailto:${item?.email}`}>
																		<p className="mb-1">{item?.email}</p>
																	</a>
																	<a href={`tel:${item?.phone_number}`}>
																		<p className="mb-1">{item?.phone_number}</p>
																	</a>
																</td>
																<td>
																	<p className="mb-1 text-capitalize">{dateFormat(item?.dob)}<br />  <span>{item?.tob ? moment(item?.tob, 'HH:mm').format('hh:mm A') : ''}</span>
																	</p>
																</td>
																{item?.price && <td>
																	<p className="mb-1">
																		₹{item?.price}
																	</p>
																</td>}
																<td>
																	<p className="mb-1 text-capitalize">
																		{item?.birth_place}
																	</p>
																</td>
																<td>
																	<p className="mb-1 text-capitalize">
																		{item?.gender?.charAt(0)?.toUpperCase() + item?.gender?.slice(1)}
																	</p>
																</td>

																<td>
																	<p className="mb-1 text-capitalize">
																		{item?.status}
																	</p>
																</td>
																<td>
																	<ul className="list-inline">
																		<li className="list-inline-item chat-icon">
																			<a href={''} className="btn btn-success eye-edit p-0" type="button"
																				data-bs-toggle="modal" data-bs-target="#exampleModal"
																				onClick={() => { getCustomerDetail(item?.id), setSelectedCustomers(item?.id) }}>
																				<img src={EditButton} alt="Edit" width={22} height={20} />
																			</a>
																		</li>

																		<li className="list-inline-item">
																			<button type="button" onClick={() => setSelectedCustomers(item?.id)} className="btn btn-primary p-0 eye-delete" data-bs-toggle="modal" data-bs-target="#DeleteCustomer">
																				<img src={DeleteButton} alt="Delete" width={16} height={16} />
																			</button>
																		</li>
																	</ul>
																</td>
															</tr>
														)
													})
											}
										</tbody>
									</table>
								</div>
							</div>
							{customers?.pagination?.total_pages > 1 &&
								<div className="card-footer">
									<div className="pagination-wrap">
										<Pagination
											total_pages={customers?.pagination?.total_pages}
											current_page={customers?.pagination?.current_page}
											onClick={(i: { [key: string]: number }) =>
												setFilterData((prev) => ({
													...prev,
													page: i.selected + 1
												}))
											}
										/>
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			</section>

			<AddEditCustomer formik={formik} previewUrl={previewUrl} handleImageChange={handleImageChange} selectedCustomers={selectedCustomers} />
			<DeleteItem title={'Customer'} modalId={'DeleteCustomer'} deleteItem={deleteItem} />
			<OpenCustomerDetails formik={formik} loading={loading} />
		</>
	)
}

export default Customers