import React, { useEffect, useState } from 'react';
import './style.scss'

import { DeleteButton, EditButton, EyeButton, Plus, UserImages } from '../assets/icons';
import { _Object } from 'utils/interfaces';
import { closeModal, dateFormat, routes } from 'utils';
import Pagination from 'views/components/form-inputs/pagination';
import { useFormik } from 'formik';
import { quotesService } from 'services/qutoes.services';
import AddEditQuotes from './add-edit';
import { DeleteItem } from 'views/components';
import { toast } from 'react-toastify';

const Quotes = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [quotes, setQuotes] = useState<_Object>({});
	const [selectedQuotes, setSelectedQuotes] = useState<any>('');
	const [filterData, setFilterData] = useState<_Object>({
		page: 1
	});

	const getQutoes = () => {
		setLoading(true)
		quotesService.getQutoes(filterData).then((data) => {
			setQuotes(data)
			setLoading(false)
		})
	}

	useEffect(() => {
		getQutoes()
	}, [filterData])

	const formik = useFormik({
		initialValues: filterData,
		enableReinitialize: true,
		onSubmit: (values: _Object) => {
			if (values.q.trim() !== '') {
				setFilterData((prev) => ({
					...prev,
					q: values.q,
					page: 1
				}));
			}
		}
	});
	console.log(formik)

	const deleteItem = () => {
		setLoading(true)

		quotesService.deleteQutoe(selectedQuotes).then((data: _Object) => {
			!data.error && getQutoes()
			data.error && toast.error(data?.message)
			setLoading(false)
			closeModal('DeleteQuotes')
		})
	}
	return (
		<>
			<section className="quotes-section">
				<div className="container-fluid">
					<div className="row">
						<div className="card">
							<div className="card-header space-between align-middles">
								<h6 className="mb-0">Quotes</h6>
								<ul className="list-inline">
									<button
										type="button"
										className="btn btn-primary"
										data-bs-toggle="modal"
										data-bs-target="#NewAppontment"
										onClick={() => setSelectedQuotes('')}
									>
										<img src={Plus} alt="Add" width={15} height={15} />&nbsp;
										Add New
									</button>
								</ul>
							</div>

							<div className="card-body p-0">
								<div className="table-responsive">
									<table className="table">
										<thead>
											<tr>
												<th>#</th>
												<th>Avtar</th>
												<th>Name</th>
												<th>Description</th>
												<th>Created date</th>
												<th>Updated date</th>
												<th className="action-button">Action</th>
											</tr>
										</thead>

										<tbody>
											{loading ?
												<tr>
													<td colSpan={12} className="text-center">
														<div className="d-flex justify-content-center">
															<div className="spinner-border text-secondary" role="status">
															</div>
														</div>
													</td>
												</tr> :

												quotes?.items?.map((item: { id: string, name: string, description: string, benefit: string, how_to_use: string, created_at: string, updated_at: string, }, i: number) => {
													return (
														<>
															<tr>
																<td>{i + 1}</td>
																<td>
																	<img src={UserImages} alt="User" width={40} height={40} className="rounded-5" />
																</td>
																<td>
																	<p className="mb-1">{item?.name}</p>
																</td>
																<td>
																	<p className="mb-1">{`${item?.description?.slice(0, 30)}`}</p>
																</td>
																<td>
																	<p className="mb-1">
																		{dateFormat(item?.created_at)}
																	</p>
																</td>
																<td>
																	<p className="mb-1">
																		{dateFormat(item?.updated_at)}
																	</p>
																</td>
																<td>
																	<ul className="list-inline">
																		<li className="list-inline-item">
																			<button type="button" className="btn btn-success  p-0 eye-edit" data-bs-toggle="modal" data-bs-target="#NewAppontment" onClick={() => setSelectedQuotes(item?.id)}>
																				<img src={EditButton} alt="Edit" width={22} height={20} />
																			</button>
																		</li>
																		<li className="list-inline-item">
																			<a href={`${routes.gem}/${item.id}`} className="btn btn-secondary eye-button p-0" >
																				<img src={EyeButton} alt="Eye" width={22} height={20} />
																			</a>
																		</li>
																		<li className="list-inline-item">
																			<button type="button" onClick={() => setSelectedQuotes(item?.id)} className="btn btn-primary p-0 eye-delete" data-bs-toggle="modal" data-bs-target="#DeleteQuotes">
																				<img src={DeleteButton} alt="Delete" width={16} height={16} />
																			</button>
																		</li>
																	</ul>
																</td>
															</tr>
														</>
													)
												})}
										</tbody>
									</table>
								</div>

							</div>
							{quotes?.pagination?.total_pages > 1 &&
								<div className="card-footer">
									<div className="pagination-wrap">
										<Pagination
											total_pages={quotes?.pagination?.total_pages}
											current_page={quotes?.pagination?.current_page}
											onClick={(i: { [key: string]: number }) =>
												setFilterData((prev) => ({
													...prev,
													page: i.selected + 1
												}))
											}
										/>
									</div>
								</div>}
						</div>

					</div>
				</div>
			</section>
			<AddEditQuotes getQutoes={getQutoes} selectedQuotes={selectedQuotes} />
			<DeleteItem title={'Quotes'} modalId={'DeleteQuotes'} deleteItem={deleteItem} />
			{/* <DeleteQuotes getQutoes={getQutoes} selectedQuotes={selectedQuotes} /> */}
		</>
	)
}

export default Quotes