import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import { Logo } from 'assets/images';
import { HandMobile } from './images';

import { routes } from 'utils';
import { _Object } from 'utils/interfaces';
import { authService } from 'services';

import { Button, InputField, LinkButton } from 'views/components';
import OTPField from 'views/components/form-inputs/otp';
import { toast } from 'react-toastify';
// import OTPInput from 'react-otp-input';

const ResetPassword = () => {
	const [loading, setLoading] = useState(false);
	const [userId, setUserId] = useState();
	const [resend, setResend] = useState(false)
	const [otpError, setOtpError] = useState()

	const navigate = useNavigate()
	const { search } = useLocation();

	const query = new URLSearchParams(search)
	const token = query.get('token')
	const formik = useFormik({
		initialValues: {
			new_password: '',
			confirm_password: '',
			token: token,
			user_id: userId,
			otp: ''
		},
		enableReinitialize: true,
		validationSchema: Yup.object({
			new_password: Yup.string()
				.required('Password is required')
				.test(
					'password-strength',
					'',
					function (value) {
						const errors: Record<string, string> = {};

						if (!value) {
							errors['required'] = 'Required';
						} else {
							if (value.length < 8) {
								errors['minLength'] = 'Min 8 characters';
							}
							if (!/[a-z]/.test(value)) {
								errors['lowercase'] = 'One lowercase';
							}
							if (!/[A-Z]/.test(value)) {
								errors['uppercase'] = 'One uppercase';
							}
							if (!/\d/.test(value)) {
								errors['number'] = 'One number';
							}
							if (!/[@$!%*?&#]/.test(value)) {
								errors['special'] = 'One special character';
							}
						}
						return Object.keys(errors).length > 0 ? this.createError({ message: errors }) : true;
					}
				),

			confirm_password: Yup.string()
				.oneOf([Yup.ref('new_password')], 'Passwords must match')
				.required('Confirm password is required')
		}),

		onSubmit: async (values: any) => {
			const formatedValue: any = { new_password: values?.new_password, confirm_password: values?.confirm_password, user_id: userId }
			setLoading(true);
			authService.updatePassword(formatedValue)
				.then((data: _Object) => {
					if (!data.error) {
						toast.success(data.message, { style: { backgroundColor: '#b7e9bc' } })
						setTimeout(() => {
							navigate(`${routes.login}`);
						}, 3000)
					} else {
						setOtpError(data.message)
					}
					setLoading(false);
				})
				.catch((error) => {
					setLoading(false);
					console.error(error);
				});
		}
	});

	const verifyOtp = async () => {
		setLoading(true);
		authService.verifyOtp({ otp: formik?.values?.otp }).then((data) => {
			if (!data?.error) {
				setUserId(data.user_id)
				setLoading(false);
			}
			if (data?.error) {
				setOtpError(data.message)
				setLoading(false);
			}
		})
	}
	console.log(setResend);

	return (
		<div className="auth-form align-middles">
			<div className="col-lg-8 col-md-12">
				<div className="card">
					<div className="row">
						<div className="col-lg-6 col-md-12 my-auto">
							<div className="login-form-col">
								<div className="brand-logo">
									<img
										src={Logo}
										alt="Logo"
										width={15}
										height={15}
										className="brand-logo-images"
									/>
									{!userId &&
										<>
											<h4>Verify OTP</h4>
											<p className="my-3">Please enter the OTP sent to your registered email to verify your account.</p>
											<OTPField
												args={{
													length: 6,
													secure: false,
													onChange: (otp: string) => otp && formik.setFieldValue('otp', otp),
													resend: resend
												}}
											/>

											<Button
												type="submit"
												name="Verify OTP"
												className="primary my-3 w-50"
												loading={loading}
												onClick={verifyOtp}
											/>
											{otpError && <p className="text-danger text-danger">{otpError}</p>}
										</>
									}
								</div>
								{userId && (
									<>
										<h4>Reset password</h4>
										<p>Type and confirm a secure new password for your account.</p>

										<form onSubmit={formik.handleSubmit}>
											<InputField
												type="password"
												name="new_password"
												label="New password"
												required={true}
												value={formik.values.new_password}
												onBlur={formik.handleBlur}
												onChange={formik.handleChange}
												error={
													formik?.touched.new_password &&
													formik.errors.new_password
												}
												password={true}
											/>
											<InputField
												type="password"
												name="confirm_password"
												label="Confirm password"
												required={true}
												value={formik.values.confirm_password}
												onBlur={formik.handleBlur}
												onChange={formik.handleChange}
												error={
													formik?.touched.confirm_password &&
													formik.errors.confirm_password
												}
											/>
											<Button
												type="submit"
												name="Reset password"
												className="primary"
												loading={loading}
											/>
											{otpError && userId && <p className="text-danger text-danger">{otpError}</p>}
										</form>
									</>
								)}
								<div className="login-link-button text-start">
									<p>
										Don&apos;t have an account?&nbsp;
										<LinkButton
											label={'Sign in'}
											path={`${routes.login}`}
											className="link px-0"
										/>
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-md-12">
							<div className="hand-moblie-col">
								<img src={HandMobile} alt="Hand Mobile" />
							</div>
						</div>
					</div>
				</div>

				<div className="already-account">
					<p>
						By continuing, you agree to&nbsp;
						<LinkButton path="#" className="link" label="Terms of use" />
						&nbsp;& <LinkButton path="#" className="link" label="Privacy Policy" />
					</p>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
