import React, { useEffect, useState } from 'react';

import * as Yup from 'yup'
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { io } from 'socket.io-client';

import { chatService } from 'services';
import { DeleteItem, Loading } from 'views/components';
import { ChatIcon, DeleteButton, Plus } from '../assets/icons';

import { _Object } from 'utils/interfaces';
import { capitalizeFirstLetter, closeModal, dateFormat, timeFormat12, truncateStringByWords } from 'utils';
import { useSelector } from 'react-redux';
import moment from 'moment';

const Chat = () => {
	const [allChats, setAllChats] = useState<any>([]);
	const [activeChatPerson, setActiveChatPerson] = useState<any>('');
	const [activeChat, setActiveChat] = useState<any>([]);
	const [selectedChat, setSelectedChat] = useState<any>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [innerLoading, setInnerLoading] = useState<boolean>(false);
	const socket = io('https://api.jahanviastro.com', {
		secure: true,
		transports: ['websocket', 'polling']
	});
	const { loggedInUser } = useSelector((state: _Object) => state.session);

	useEffect(() => {
		if (selectedChat) {
			socket.emit('joinRoom', selectedChat);
			console.log(`Joined room: ${selectedChat}`);
		}

		const handleNewMessage = (data: any) => {
			console.log('Received message data:', data);
			console.log('Current selectedChat:', selectedChat);

			if (data && data.user_id !== loggedInUser?.id) {
				const newMessage = { ...data };

				setActiveChat((prevMessages: any) => {
					return {
						...prevMessages,
						items: [newMessage, ...prevMessages.items]
					};
				});
			} else {
				console.log(`Message not for selected chat. Expected: ${selectedChat}, Received: ${data.user_id}`);
			}
		};

		socket.on('newMessage', handleNewMessage);

		return () => {
			socket.off('newMessage', handleNewMessage);
			socket.disconnect();
		};
	}, [selectedChat]);

	const getChats = () => {
		setLoading(true)
		chatService.getChats().then((data: any) => {
			setAllChats(data)
			setLoading(false)
		})
	}

	useEffect(() => {
		getChats()
	}, [])

	const deleteItem = () => {
		setLoading(true)
		chatService.deleteChat(selectedChat).then((data: _Object) => {
			!data.error && getChats()
			setLoading(false)
			closeModal('DeleteChat')
			toast.success(data.message, { style: { backgroundColor: '#b7e9bc' } })
		})
	}

	const getSingleChat = (id: string) => {
		setInnerLoading(true)
		setSelectedChat(id)
		chatService.getChatDetail(id).then((data) => {
			if (!data.error) {
				setActiveChat(data)
				setInnerLoading(false)
			}
		})
	}

	const postChat = () => {
		setInnerLoading(true)
		chatService.postChat(selectedChat, { message: formik.values.message }).then((data) => {
			if (!data.error) {
				getSingleChat(selectedChat)
				formik.setFieldValue('message', '')
				setInnerLoading(false)
			}
		})
	}

	const formik = useFormik({
		initialValues: { message: '' },
		enableReinitialize: true,
		validationSchema: Yup.object({
			message: Yup.string().required()
		}),

		onSubmit: async () => {
			postChat()
		}
	})

	return (
		<>
			<section className="gems-section">
				<div className="container-fluid">
					<div className="row">
						<div className="card">
							<div className="card-header space-between align-middles">
								<h6 className="mb-0">Chats</h6>

								<button
									type="button"
									className="btn btn-primary hidden"
									data-bs-toggle="modal"
									data-bs-target="#AddEditGems"
									onClick={() => setSelectedChat('')}
								>
									<img src={Plus} alt="Add" width={15} height={15} />&nbsp;
									Add New
								</button>

							</div>

							<div className="card-body p-0">
								<div className="table-responsive">
									<table className="table">
										<thead>
											<tr>
												<th>#</th>
												<th>Name</th>
												<th>Role</th>
												<th>Status</th>
												<th>Created date</th>
												<th className="action-button">Action</th>
											</tr>
										</thead>

										<tbody>
											{loading ?
												<tr>
													<td colSpan={12} className="text-center">
														<div className="d-flex justify-content-center">
															<div className="spinner-border text-secondary" role="status">
															</div>
														</div>
													</td>
												</tr> :

												allChats?.items?.map((item: any, i: number) => {
													return (
														<tr key={i}>
															<td>{i + 1}</td>
															<td>
																<p className="mb-1 text-nowrap text-capitalize">{truncateStringByWords(capitalizeFirstLetter(item.user.name), 10)}</p>
															</td>
															<td>
																<p className="mb-1 text-break text-capitalize">
																	{capitalizeFirstLetter(item.user.role)}
																</p>
															</td>

															<td>
																<p className="mb-1 text-break text-capitalize">
																	{capitalizeFirstLetter(item.status)}
																</p>
															</td>

															<td>
																<p className="mb-1">
																	{dateFormat(item.created_at)}
																</p>
															</td>
															<td>
																<ul className="list-inline">
																	<li className="list-inline-item grid justify-content-end my-1">
																		<a href={'#'} className="btn btn-success eye-button p-0"
																			data-bs-toggle="modal"
																			data-bs-target="#chatModalOpen"
																			onClick={() => { getSingleChat(item.id), setActiveChatPerson(item?.user?.name) }}
																		>
																			<img src={ChatIcon} alt="Chat" width={22} height={20} />
																		</a>
																	</li>
																	<li className="list-inline-item grid justify-content-end my-1">
																		<button type="button" onClick={() => setSelectedChat(item?.id)} className="btn btn-primary p-0 eye-delete" data-bs-toggle="modal" data-bs-target="#DeleteChat">
																			<img src={DeleteButton} alt="Delete" width={16} height={16} />
																		</button>
																	</li>
																</ul>
															</td>
														</tr>
													)
												})}
										</tbody>
									</table>
								</div>

							</div>
							{/* {gems?.pagination?.total_pages > 1 &&
								<div className="card-footer">
									<div className="pagination-wrap">
										<Pagination
											total_pages={gems?.pagination?.total_pages}
											current_page={gems?.pagination?.current_page}
											onClick={(i: { [key: string]: number }) =>
												setFilterData((prev) => ({
													...prev,
													page: i.selected + 1
												}))
											}
										/>
									</div>
								</div>
							} */}
						</div>

					</div>
				</div>
			</section>
			<DeleteItem title={'Chat'} modalId={'DeleteChat'} deleteItem={deleteItem} />
			<div className="chat-view-modal">
				<div className="modal fade" id="chatModalOpen" aria-labelledby="ChatModalLabel" aria-hidden="true" data-bs-backdrop="static" tabIndex={-1}>
					<div className="modal-dialog modal-sm chat-modal">
						<div className="modal-content">
							<div className="modal-header bg-white">
								<strong><p className="mb-0 mx-auto">Chat with {capitalizeFirstLetter(activeChatPerson)}</p></strong>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
							</div>
							<div className="modal-body p-0 bg-light">
								<div className="chat-window p-3">
									{activeChat?.items?.slice().reverse().reduce((acc: any[], chat: any) => {
										const currDate = dateFormat(chat?.updated_at);
										const todayDate = moment().format('MMM DD, yyyy');
										const lastGroup = acc[acc.length - 1];
										const isNewDay = !lastGroup || dateFormat(lastGroup.date) !== currDate;

										if (isNewDay) {
											acc.push({
												date: currDate,
												isToday: currDate === todayDate,
												messages: []
											});
										}
										acc[acc.length - 1].messages.push(chat);

										return acc;
									}, []).map((group: any, i: number) => (
										<React.Fragment key={i}>
											<small className="text-center">
												{group.isToday ? 'Today' : group.date}
											</small>

											{group.messages.map((chat: any, index: number) => (
												<React.Fragment key={index}>
													{chat?.user?.role === 'admin' && (
														<div className="chat-message me-3 text-end mb-2">
															<div className="d-inline-block p-2 bg-primary text-white rounded text-start">
																{chat?.message}&nbsp;&nbsp;
																<small>{timeFormat12(chat?.updated_at)}</small>
															</div>
														</div>
													)}

													{chat?.user?.role === 'customer' && (
														<div className="chat-message ms-3 text-start mb-2">
															<div className="d-inline-block p-2 bg-secondary text-white rounded text-start">
																{chat?.message}&nbsp;&nbsp;
																<small>{timeFormat12(chat?.updated_at)}</small>
															</div>
														</div>
													)}
												</React.Fragment>
											))}
										</React.Fragment>
									))}
								</div>

							</div>
							<div className="modal-footer bg-white p-2">
								<form onSubmit={formik.handleSubmit}>
									<div className="input-group">
										<input type="text" className="form-control chat-input-box" placeholder="Type your message..." name="message" value={formik.values.message} aria-label="Chat input" onChange={formik.handleChange} />
										<button className="btn btn-primary p-0 px-3" type="submit">{innerLoading ? <Loading /> : 'Send'}</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Chat