import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { gemsService } from 'services/gems.service';
import { capitalizeFirstLetter } from 'utils';
import { _Object } from 'utils/interfaces';
import { Loading } from 'views/components';

const OpenGemsDetails = ({ selectedGems }: _Object) => {
	const [details, setDetails] = useState<any>();
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (selectedGems) {
			setLoading(true)
			gemsService.getGemsDetails(selectedGems).then((data) => {
				setLoading(false)
				setDetails(data);
			});
		}
	}, [selectedGems]);
	console.log('details', details);

	return (
		<>
			<div className="gemstone-details-modal">
				<div
					className="modal fade"
					id="OpenGemsDetails"
					aria-labelledby="OpenGemsDetails"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">

							<div className="modal-header">
								<h5 className="mb-0">Gemstone Details</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
								</button>
							</div>
							<div className="modal-body" style={{ minHeight: '440px' }}>
								{loading ? <div className="text-center"><Loading /></div> :
									<div className="row">
										<div className="col-4">
											{details?.images?.path ? <img src={details?.images?.path} width={300} height={300} className="img-fluid" />
												:
												<div className="card">
													<img src="..." className="card-img-top" alt="" />
												</div>
											}
										</div>
										<div className="col-8">
											<div className="row">
												<div className="col-auto">
													{details?.title && <div className="name-details">
														<h6>Title </h6>
														<p>{details?.title}</p>
													</div>
													}
												</div>
												<div className="col-auto">
													{details?.created_at && (
														<div className="create-date">
															<h6>Created date</h6>
															<p>{moment(details?.created_at).utc().format('DD MMM, YYYY')}</p>
														</div>
													)}
												</div>
												<div className="col-auto">
													<div className="create-date">
														<h6>Status</h6>
														<p>{details?.status ? capitalizeFirstLetter(details?.status) : '-'}</p>
													</div>
												</div>
												<div className="col-12">
													{details?.description && (
														<div className="details-description my-2">
															<h6>Description</h6>
															<div dangerouslySetInnerHTML={{ __html: details?.description }} />
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
								}
								<div>
									{details?.benefits && (
										<div className="details-benefits">
											<h6>Benefits</h6>
											<div dangerouslySetInnerHTML={{ __html: details?.benefits }} />
										</div>
									)}

									{details?.how_to_use && (
										<div className="details-description">
											<h6>How to use</h6>
											<div dangerouslySetInnerHTML={{ __html: details?.how_to_use }} />
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default OpenGemsDetails;

