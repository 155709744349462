import React, { useEffect, useState } from 'react';
import './style.scss'

import axios from 'axios';
import store from 'store';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import { _Object } from 'utils/interfaces';
import { closeModal, dateFormat } from 'utils';
import { customersService } from 'services/customers.service';
import { AddEditCustomer, DeleteItem, Loading, OpenCustomerDetails } from 'views/components';
import { DeleteButton, EditButton, Plus } from '../assets/icons';

const Team = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [staffs, setStaffs] = useState<_Object>({});
	const [selectedStaff, setSelectedStaff] = useState<any>('');
	const [selectedFile, setSelectedFile] = useState<any>(null);
	const [previewUrl, setPreviewUrl] = useState('');

	const [filterData, setFilterData] = useState<_Object>({
		page: 1,
		'filters[role]': 'staff',
		role: 'staff'
	});
	console.log(setFilterData);

	const getCustomer = () => {
		setLoading(true)
		customersService.getCustomers(filterData).then((data: any) => {
			setStaffs(data)
			setLoading(false)
		})
	}

	useEffect(() => {
		getCustomer()
	}, [filterData])

	const deleteItem = () => {
		setLoading(true)
		customersService.deleteCustomers(selectedStaff).then((data: _Object) => {
			!data.error && getCustomer()
			setLoading(false)
			closeModal('DeleteStaff')
			toast.success(data.message, { style: { backgroundColor: '#b7e9bc' } })
		})
	}

	const formik: any = useFormik({
		initialValues: filterData,
		enableReinitialize: true,
		validationSchema: Yup.object({
			name: Yup.string().required('Name is required'),
			email: Yup.string().email('Invalid email address').required('Email is required'),
			phone_number: Yup.string().required('Phone number is required'),
			dob: Yup.string().required('Date of Birth is required'),
			tob: Yup.string().required('Time of Birth is required'),
			birth_place: Yup.string().required('Birth Place is required'),
			gender: Yup.string().required('Gender is required'),
			status: Yup.string().required('Status is required')
		}),
		onSubmit: (values: _Object) => {
			const { password, page, ...filteredValues } = values;
			delete filteredValues['filters[role]'];
			if (selectedStaff) {
				customersService.updateCustomers(selectedStaff, filteredValues).then((customer) => {
					if (!customer.error) {
						toast.success(customer.message, { style: { backgroundColor: '#b7e9bc' } })
						closeModal('exampleModal')
						if (selectedFile) {
							handleUpload(selectedStaff)
						}
						getCustomer()
					}
					console.log('c', password, page);
				})
			} else {
				customersService.createCustomers(filteredValues).then((customer) => {
					if (!customer.error) {
						toast.success(customer.message, { style: { backgroundColor: '#b7e9bc' } })
						closeModal('exampleModal')
						if (selectedFile) {
							handleUpload(customer.user_id)
						}
						getCustomer()
					}
				})
			}
		}
	});

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files ? e.target.files[0] : null;
		if (file) {
			setSelectedFile(file);
			setPreviewUrl(URL.createObjectURL(file));
		}
	};

	const handleUpload = async (id: string) => {
		if (selectedFile) {
			const data = new FormData();
			data.append('entity_id', id);
			data.append('files', selectedFile);
			try {
				const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/admin/media`, data, {
					headers: { 'Content-Type': 'multipart/form-data', 'Authorization': store.get(process.env.REACT_APP_ACCESS_TOKEN_KEY || 'bIESIcg42Wb9') }
				});
				response && getCustomer()
			} catch (error) {
				console.error('Error uploading image:', error);
			}
		}
	}

	const getCustomerDetail = (id: string) => {
		setLoading(true);
		customersService.getCustomerDetails(id).then((item: _Object) => {
			formik.setValues((prevValues: _Object) => ({
				...prevValues,
				...item
			}));
			setLoading(false);
		});
	};

	// const handleStatusChange = (e: any, id: any) => {
	// 	const updatedStatus = e?.target?.value;
	// 	customersService.updateCustomers(id, { status: updatedStatus }).then((data: any) => {
	// 		!data.error && getCustomer()
	// 	})
	// };
	console.log('staffs?.items?.length', staffs?.items?.length);

	return (
		<>
			<section className="team-section">
				<div className="container-fluid">
					<div className="row">
						<div className="card">
							<div className="card-header space-between align-middles">
								<h6 className="mb-0">Staffs</h6>
								<button
									type="button"
									className="btn btn-primary"
									data-bs-toggle="modal" data-bs-target="#exampleModal"
									onClick={() => { formik.resetForm(), setSelectedStaff('') }}
								>
									<img src={Plus} alt="Add" width={15} height={15} />&nbsp;
									Add New
								</button>
							</div>

							<div className="card-body table-responsive">
								<table className="table">
									<thead>
										<tr>
											<th>#</th>
											<th style={{ whiteSpace: 'nowrap' }}>Full Name</th>
											<th style={{ whiteSpace: 'nowrap' }}>Contact Info.</th>
											<th style={{ whiteSpace: 'nowrap' }}>DOB/Birth Time</th>
											<th style={{ whiteSpace: 'nowrap' }}>Birth Place</th>
											<th>Gender</th>
											<th>Status</th>
											<th className="action-button">Action</th>
										</tr>
									</thead>
									<tbody>

										{loading && <tr>
											<td colSpan={12} className="text-center">
												<div className="d-flex justify-content-center">
													<tr>
														<td>
															<Loading />
														</td>
													</tr>
												</div>
											</td>
										</tr>}

										{staffs?.items?.length === 0 ?
											<tr>
												<td colSpan={12} className="text-center">
													<div className="d-flex justify-content-center">
														<tr>
															<td>
																No record found!
															</td>
														</tr>
													</div>
												</td>
											</tr>
											:
											staffs?.items?.map((item: any, i: number) => {
												return (
													<>
														<tr>
															<td>{i + 1}</td>
															<td>
																<p className="mb-1 text-capitalize">
																	{item?.name}
																</p>
															</td>
															<td>
																<a href={`mailto:${item?.email}`}>
																	<p className="mb-1">{item?.email}</p>
																</a>
																<a href={`tel:${item?.phone_number}`}>
																	<p className="mb-1">{item?.phone_number}</p>
																</a>
															</td>
															<td>
																<p className="mb-1">{dateFormat(item?.dob)}<br /> <span>12:30</span>
																</p>
															</td>
															{item?.price && <td>
																<p className="mb-1">
																	₹{item?.price}
																</p>
															</td>}
															<td>
																<p className="mb-1 text-capitalize">
																	{item?.birth_place}
																</p>
															</td>
															<td>
																<p className="mb-1 text-capitalize">
																	{item?.gender?.charAt(0)?.toUpperCase() + item?.gender?.slice(1)}
																</p>
															</td>

															<td>
																<p className="mb-1 text-capitalize">
																	{item?.status}
																</p>
															</td>

															<td>
																<ul className="list-inline">
																	<li className="list-inline-item chat-icon">
																		<a href={''} className="btn btn-success eye-edit p-0" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"
																			onClick={() => { getCustomerDetail(item?.id), setSelectedStaff(item?.id) }}
																		>
																			<img src={EditButton} alt="Edit" width={22} height={20}

																			/>
																		</a>
																	</li>
																	{/* <li className="list-inline-item">
																		<a href={''} className="btn btn-secondary eye-button p-0" type="button"
																			data-bs-toggle="modal" data-bs-target="#OpenCustomerDetails"
																			onClick={() => getCustomerDetail(item?.id)}
																		>
																			<img src={EyeButton} alt="Eye" width={22} height={20} />
																		</a>
																	</li> */}

																	<li className="list-inline-item">
																		<button type="button"
																			onClick={() => setSelectedStaff(item?.id)}
																			className="btn btn-primary p-0 eye-delete" data-bs-toggle="modal" data-bs-target="#DeleteStaff">
																			<img src={DeleteButton} alt="Delete" width={16} height={16} />
																		</button>
																	</li>
																</ul>
															</td>
														</tr>
													</>
												)
											})
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</section>
			<AddEditCustomer formik={formik} previewUrl={previewUrl} handleImageChange={handleImageChange} selectedCustomers={selectedStaff} staff={true} />
			<DeleteItem title={'Staff'} modalId={'DeleteStaff'} deleteItem={deleteItem} />
			<OpenCustomerDetails formik={formik} loading={loading} />
		</>
	)
}

export default Team